import axios from "axios";

import store from "../store/store";

/**
 * here we have the base url for all http request
 * that will be made to the REST API
 */
const DEV_url = "http://209.105.243.210:8800/api/v1/";
const LIVE_url = "https://applicant-api.espamformationuniversity.com/api/";
export const url = LIVE_url;

/**
 * here we have all the possible enpoints that can be
 * used to access data on the REST API
 */
export const endpoints = {
  ADMIN_LOGIN: "/admin/login", // POST
  STUDENT_LOGIN: "/student/login", // POST
  SIGNUP: "/signup",
  GET_STUDENTS: "/students",
  ADD_STUDENT: "/addstudent",
  GET_QUESTIONS: "/questions",
  ADD_QUESTION: "/question/add",
  DELETE_QUESTION: "/question/:id",
  DELETE_USER: "/user/:id",
  ME: "/me",
  UPDATE_CBT_SCORE: "/updatecbtscore",
  SUBMIT_SECOND: "/submit_second",
  SUBMIT_THIRD: "/submit_third",
  UPDATE_SECOND_SCORE :"/updatesecondscore",
  UPDATE_THIRD_SCORE :"/updatethirdscore",
  STATS :"/stats",
  UPDATE_DEADLINE: "update-deadline/:id",
  RESET: "reset/:id"
};

/**
 * the in key is used to validate every request made to the REST API
 * this ensures that the request is secure and permitted.
 */
export const apiKey = "";

/**
 * default headers for http request
 */
export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};


/**
 * parse error response
 */
function parseError(error) {
  if (!error) {
    return Promise.reject("An unknown error occured.");
  }

  //console.log(error.response.data.error);

  if (typeof error === "string") {
    return Promise.reject(error);
  }

  if (error && error.response) {
    if (error.response.data && error.response.data.error) {
      return Promise.reject(error.response.data.error);
    }

    if (error.response.data && error.response.data.message) {
      return Promise.reject(error.response.data.message);
    }

    return Promise.reject("An unknown error occured.");
  }

  return Promise.reject(error.message || "An unknown error occured.");
}

/**
 * parse response
 */
function logout() {
  return {
    type: "LOGOUT_USER"
  };
}

function parseBody(response) {
  const { data } = response;
  if (data && data.status) {
    return data;
  } else {
    return parseError(new Error(data.message));
  }
}

let instance = axios.create({
  baseURL: url
});

/**
 * add a response interceptor for axios instace
 */
instance.interceptors.response.use(
  response => {
    if (response.status == 401 || response.status == 403) {
      store.dispatch(logout());
      window.location.replace("/");
    }
    return parseBody(response);
  },
  error => {
    if (error.message === "Network Error") {
      return Promise.reject(error.message);
    }

    return parseError(error);
  }
);

// request header
instance.interceptors.request.use(
  async config => {
    try {
      const {
        auth: { authToken }
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      // check if a user was found
      if (authToken) {
        // add user login token to request header
        config.headers = Object.assign({}, config.headers, {
          Authorization: `Bearer ${authToken}`
        });
      }
      // return transformed config
      return config;
    } catch (error) {
      // reject error if failed
      return Promise.reject(error);
    }
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * export instance as http
 */
export const http = instance;
